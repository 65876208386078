import React from "react";
import {Link} from "gatsby";

import Layout from "../../components/layout";
import {GettingStartedSidebar as Sidebar} from "../../components/sidebar";
import {GettingStartedBreadcrumb as Breadcrumb} from "../../components/breadcrumb";
import {AskHowToInfoAlert} from "../../components/alert";
import {StepRow} from "../../components/table";
import {CodeSnippet} from "../../components/snippet";
import {ApiLink} from "../../components/link";
import {KeyHeader} from "../../components/typography";

const pageTitle = "Call Stored Procedure";

const Page = () => (
    <Layout pageTitle={pageTitle}
            sidebarNav={<Sidebar/>}
            mobileNav={<Breadcrumb activeName={pageTitle}/>}
    >
        <p>
            Calling (executing) a stored procedure (function) requires the following steps:
        </p>

        <table className="mb-3">
            <tbody>
            <StepRow name="Step 1." href="#create_command">
                Initialize stored procedure command
            </StepRow>
            <StepRow name="Step 2." href="#bind_input_params">Bind input parameters</StepRow>
            <StepRow name="Step 3." href="#execute_command">Execute stored procedure</StepRow>
            <StepRow name="Step 4." href="#process_output">Process output</StepRow>
            </tbody>
        </table>

        <p>
            Let's say we want to call a procedure {' '}<code>TestProc</code>{' '} with
            two numeric parameters {' '}<code>n1</code>{' '} and {' '}<code>n2</code>{' '} where
            {' '}<code>n1</code>{' '} is input parameter and {' '}<code>n2</code>{' '} is input/output parameter.
            Procedure will add {' '}<code>n1</code>{' '} and {' '}<code>n2</code>{' '} and assign the result
            to {' '}<code>n2</code>{' '} (<code>n2 = n1+n2</code>).
        </p>

        <KeyHeader id="create_command">Initialize Stored Procedure Command</KeyHeader>
        <p>
            As usual, the first thing to do is to create the objects. To
            execute a stored procedure we need two objects:
            {' '}<ApiLink>SAConnection</ApiLink>{' '} (connection object) and
            {' '}<ApiLink>SACommand</ApiLink>{' '} (command object):
        </p>
        <CodeSnippet>{`
SACommand cmd(&con, "TestProc");
            `.trim()}
        </CodeSnippet>

        <p>
            The line above creates a command object {' '}<code>cmd</code>{' '} based on
            previously created and connected connection object {' '}<code>con</code>{' '}
            (for initializing {' '}<code>con</code>{' '} object see
            {' '}<Link to="/HowTo/connect/">Connect to database</Link>). Second parameter is
            the name of the required procedure. Required procedure can also be
            provided using {' '}<ApiLink>SACommand::setCommandText</ApiLink>{' '}
            method:
        </p>
        <CodeSnippet>{`
SACommand cmd(&con);
cmd.setCommandText(_TSA("TestProc"));
            `.trim()}
        </CodeSnippet>

        <KeyHeader id="bind_input_params">Bind Input Parameters</KeyHeader>
        <p>
            After parameters are created (automatically by the library or manually) they all initialized to null
            values. There are two methods of assigning values to them.
        </p>

        <p>
            Using ordinary assignment:
        </p>
        <CodeSnippet>{`
cmd.Param(_TSA("n1")).setAsInt32() = 5;
cmd.Param(_TSA("n2")).setAsInt32() = 10;
            `.trim()}
        </CodeSnippet>

        <p>
            Using stream binding operator:
        </p>
        <CodeSnippet>{`
cmd << SAPos(_TSA("n1")) << 5 << SAPos(_TSA("n2")) << 10;
            `.trim()}
        </CodeSnippet>

        <KeyHeader id="execute_command">Execute Stored Procedure</KeyHeader>
        <p>
            To actually execute a procedure call
            {' '}<ApiLink>SACommand::Execute</ApiLink>{' '} method:
        </p>
        <CodeSnippet>{`cmd.Execute();`}</CodeSnippet>

        <KeyHeader id="process_output">Process Output</KeyHeader>
        <p>
            If stored procedure does not generate result set(s) you can
            reach output parameters immediately after calling
            {' '}<ApiLink>SACommand::Execute</ApiLink>:
        </p>
        <CodeSnippet>{`printf("%d\\n", cmd.Param(_TSA("n2")).asInt32());`}</CodeSnippet>

        <p>
            After the statement has been executed, SQLAPI++ stores the
            returned values of output parameters in the
            {' '}<ApiLink>SAParam</ApiLink>{' '} objects bound to those
            parameters. On some servers these returned values are not guaranteed to
            be set until all results returned by the procedure have been fetched
            (using {' '}<ApiLink>SACommand::FetchNext</ApiLink>{' '}
            method). See {' '}<Link to="/ApiDoc/servers/">Server specific information</Link>{' '} on output parameters
            availability on different DBMSs.
        </p>
        <p>
            For processing result set(s), if any,
            see {' '}<Link to="/HowTo/fetch/">Fetch result set(s)</Link>.
        </p>
        <p>
            {' '}<a href="#bind_input_params">Steps 2 to 4</a>{' '} can be
            repeated if you need to call procedure several times.
        </p>

        <AskHowToInfoAlert/>
    </Layout>
);

export default Page;
